import React from 'react';
import { useObservable } from 'rxjs-hooks';

import { blocService, ModalTypes } from '../../bloc/bloc.service';

import './tank.component.scss';

const TankComponent: React.FC = () => {
  const currentTank = useObservable(() => blocService.currentTank);
  const activeModal = useObservable(() => blocService.activeModal);
  const activeClass = activeModal === ModalTypes.Tank ? ' active' : '';
  
  let tankChartWidth = 0;

  const dissmissModals = () => blocService.dissmissModals();

  const fullFillTank = () => {
    currentTank && blocService.setTankFull(currentTank);
    dissmissModals();
  }

  if (currentTank) {
    tankChartWidth = 100 / currentTank.size * currentTank.balance;
  }
  
  return (
    <section className={'Modal TankComponent' + activeClass}>
      <div className="ModalBackdrop"></div>
      <div className="ModalBody">
        <h1>
          <span className="closeBtn" onClick={dissmissModals}>&times;</span>
          <span>Бак {currentTank && currentTank.id}</span>
        </h1>
        <hr />
        <div className="TankData">
          <div className="TankDataBlock">
            <h6>Объём бака</h6>
            <h4>{currentTank && currentTank.size} л</h4>
          </div>
          <div className="TankDataBlock">
            <h6>Заполнен:</h6>
            <h4>{currentTank && currentTank.balance} л</h4>
          </div>
        </div>
        <div className="TankChart">
          <div className="TankChartBlock" style={{ width: tankChartWidth + '%'}}></div>
        </div>
        <hr />
        <p className="TankText textCenter">
          Вы можете заправить бак до паспортного объема
        </p>
        <div className="Buttons textCenter">
          <button className="Btn BtnBlock" onClick={fullFillTank}>Заправить полный бак</button>
        </div>
      </div>
    </section>
  )
}

export default TankComponent;
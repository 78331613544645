import { BehaviorSubject, Subject } from "rxjs";

import { of } from "rxjs";
import { map } from 'rxjs/operators';

import { axiosInstance } from './api.service';

import { Stats, Field, Config, TankConfig } from "./interfaces";

enum ModalTypes { None, Connetions, Setting, Configuration, Tank, Field, FieldComplete };
enum ConnectionsStatus { None, Conneted };

const TESTMODE = false;
const dummy_config = { 'status': 'ok', 'array': [0, 0, 1, 1, 0, 50.512859, 30.446239, 7569.76, 5768.88, 4553.76, 7, 2, 2, 2, 7.00, 17.00, 2.00, 1.78, 1.78, 1.78, 3, 50, 60, 120, 20, 8000.00, 6000.00, 5000.00, 3.00, 50.00, 0, 0, 0, 0, 1, 3, 10, 1, 1, 10, 10, 'Stop'] };
const dummy_stat = { 'status': 'ok', 'array': [15.52, 18.45, 7.76, 9.22, 6305.94, 9891.36, 5, 103.11, 8400.16, 5, 6305.94, 7214.41, 9, 'Stop'] };

class Bloc {
  public currentTank: Subject<TankConfig> = new Subject();

  public activeModal = new BehaviorSubject(ModalTypes.None);
  public connectionStatus = new BehaviorSubject(ConnectionsStatus.None);

  public field: BehaviorSubject<Field | any> = new BehaviorSubject(null);

  public needUpdate = new Subject();

  private _config: Config | null = null;
  

  constructor() {
    this.checkNetwork();

    this.field.subscribe(r => {
      r && this.save(r);
    });

    this.loadField();

    
  }

  // CORE

  private loadField() {
    const storedField = this.load();
    if (storedField)
      this.field.next(storedField);
  }

  private checkNetwork() {
    if (TESTMODE)
      setTimeout(() => {
        this.connectionStatus.next(ConnectionsStatus.Conneted);
      }, 1000);
    else
      setInterval(() => {
          axiosInstance.get('get?key=stat').toPromise()
          .then(r => {
            this.connectionStatus.next(ConnectionsStatus.Conneted)
          })
          .catch(e => {
            console.log('connectionStatus error', e);
            this.connectionStatus.next(ConnectionsStatus.None)
          })
      }, 30000);
  }

  // MODAL

  public openModal(type: ModalTypes): void {
    this.activeModal.next(type);
  };

  public dissmissModals(): void {
    this.activeModal.next(ModalTypes.None);
  }

  // API

  public getConfig() {
    if (TESTMODE) {
      this._config = this._config || this.arrToConfig(dummy_config.array);
      return of(this._config);
    } else return axiosInstance
        .get('get?key=config')
      .pipe(map(r => {
        const parsed = JSON.parse(r.data.split("'").join('"'));
        return this.arrToConfig(parsed.array)
      }));
  }

  public setConfig() {
    return axiosInstance.get('set?key=setconfig');
  }

  public setManual() {
    return axiosInstance.get('set?key=manual');
  }

  public setTankFull(tank: TankConfig) {
    const id = tank.id;

    if (this._config) {
      const _tank = this._config.tanks.find(v => v.id = id);
      if (_tank) _tank.balance = _tank.size;
    }

    // TODO: just to update dash
    this.loadField();

    return axiosInstance.get('set?key=tank' + id + 'full');
  }

  public getStat() {
    if (TESTMODE) return of(this.arrToStat(dummy_stat.array));
      
    return axiosInstance
        .get('get?key=stat')
      .pipe(map(r => {
        const parsed = JSON.parse(r.data.split("'").join('"'));
        return this.arrToStat(parsed.array)
      }));
  }

  public clearStat() {
    return axiosInstance.get('set?key=clearall');
  }

  public clearField() {
    this.field.next(null);
    this.clear();
  }

  // PRIVATE

  private arrToStat(arr: any[]): Stats {
    return {
      milage: {
        field: arr[0] || 0,
        total: arr[1] || 0,
      },
      area: {
        field: arr[2] || 0,
        total: arr[3] || 0,
      },
      tanks: [
        {
          field: arr[4] || 0,
          total: arr[5] || 0,
          refuel: arr[6] || 0
        },
        {
          field: arr[7] || 0,
          total: arr[8] || 0,
          refuel: arr[9] || 0
        },
        {
          field: arr[10] || 0,
          total: arr[11] || 0,
          refuel: arr[12] || 0
        }
      ]
    }
  }

  public configToParams(config: Config) {
    const normString = config.tanks.map((tank, i) => `norm${i+1}=${tank.norm}`);
    return `?global_mode=${config.globalMode}&diff_mode=${config.diffMode}&` + normString.join('&');
  }

  public configToParams2(config: Config) {
    let result = `?device_count=3&device_length=${config.deviceLength}`;
    result += `&min_velocity=${config.minVelocity}`;
    result += `&pump_power_max=${config.pumpPowerMax}`;

    result += `&min_sensor=${config.sensor.min}`;
    result += `&max_sensor=${config.sensor.max}`;
    result += `&alert_time=${config.alertTime}`;
    result += `&injection_width=${config.injectionWidth}`;
    result += `&time_metric=${config.timeMetric}`;
    result += `&max_pressure=${config.maxPressure}`;
    result += `&time_start=${config.timeStart}`;
    
    const sizeTankString = config.tanks.map((tank, i) => `size_tank${i + 1}=${tank.size}`);
    result += '&' + sizeTankString.join('&');

    return result;
  }

  private arrToConfig(arr: any[]): Config {
    return {
      globalStatus: arr[0] || 0,
      globalMode: arr[1] || 0,
      gps: !!arr[2],
      wifi: !!arr[3],
      diffMode: !!arr[4],
      coordinates: { x: arr[5] || 0, y: arr[6] || 0 },
      speed: arr[10] || 0,
      deviceCount: arr[20] || 0,
      deviceLength: arr[21] || 0,
      minVelocity: arr[28] || 0,
      pumpPowerMax: arr[29] || 0,
      manualVirtualSpeed: arr[30] || 0,
      sensor: { min: arr[34] || 0, max: arr[35] || 0 },
      alertTime: arr[36] || 0,
      injectionWidth: arr[37] || 0,
      timeMetric: arr[38] || 0,
      maxPressure: arr[39] || 0,
      timeStart: arr[40] || 0,

      tanks: [
        {
          balance: arr[7] || 0,
          sensorPressure: arr[11] || 0,
          pumpPower: arr[14] || 0,
          rate: arr[17] || 0,
          norm: arr[22] || 0,
          size: arr[25] || 0,
          manualPower: arr[31] || 0,
          id: 1
        },
        {
          balance: arr[8] || 0,
          sensorPressure: arr[12] || 0,
          pumpPower: arr[15] || 0,
          rate: arr[18] || 0,
          norm: arr[23] || 0,
          size: arr[26] || 0,
          manualPower: arr[32] || 0,
          id: 2
        },
        {
          balance: arr[9] || 0,
          sensorPressure: arr[13] || 0,
          pumpPower: arr[16] || 0,
          rate: arr[19] || 0,
          norm: arr[24] || 0,
          size: arr[27] || 0,
          manualPower: arr[33] || 0,
          id: 3
        }
      ]
    }
  }

  private save(data: Field | null) {
    localStorage.setItem('app', JSON.stringify(data));
  }

  private load(): Field {
    const data = localStorage.getItem('app');
    return data && JSON.parse(data);
  }

  private clear() {
    localStorage.setItem('app', 'null');
  }
}

const blocService = new Bloc();

export { blocService, ModalTypes }; 
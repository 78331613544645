import React from 'react';
import { useObservable } from 'rxjs-hooks';

import { blocService, ModalTypes } from '../../bloc/bloc.service';
import { Field, Config, GlobalMode, TankConfig, Stats } from '../../bloc/interfaces';

import AddIcon from '../../assets/img/add-icon.svg';
import './dash-blocks.component.scss';


const DashBlocksComponent: React.FC = () => {
  const field: Field | null = useObservable(() => blocService.field);
  const config: Config | null = useObservable(() => blocService.getConfig());
  const stats: Stats | null = useObservable(() => blocService.getStat());

  const tankClass = (i: number) => {
    if (!config) return '';
    return 'DashBlock DashBlockFirst ' + (config.tanks[i].balance > 0 ? 'active' : 'danger');
  }

  const rateClass = (i: number) => {
    if (!config) return '';
    return 'DashBlockInner DashBlockInnerData ' + (config.tanks[i].rate <= config.tanks[i].norm ? 'active' : 'danger');
  }

  const onTankClick = (tank: TankConfig) => {
    blocService.currentTank.next(tank);
    blocService.openModal(ModalTypes.Tank);
  }

  const onFieldClick = () => {
    const modal = field ? ModalTypes.FieldComplete : ModalTypes.Field;
    blocService.openModal(modal);
  }

  if (!config) return <div></div>;

  return (
    <section className="DashBlocksComponent">
      <header className="DashBlocksComponentHeader">
        <div className="DashBlock" onClick={onFieldClick}>
          {!!field && <h6 className="head">Поле</h6>}
          {!!field && <div className="text">{field.name}</div>}
          {!field && (
            <div className="AddField">
              <img src={AddIcon} alt="add" />
              <span>Создать поле</span>
            </div>
          )}
        </div>
        <div className="DashBlock">
          <h6 className="head">Режим</h6>
          <div className="text">
            {config.globalMode === GlobalMode.Auto && <span>Автомат</span>}
            {config.globalMode === GlobalMode.Manual && <span>Ручной</span>}
            {config.globalMode === GlobalMode.Test && <span>Test</span>}
          </div>
        </div>
        <div className="DashBlock">
          <h6 className="head">обработано</h6>
          <div className="text">{stats && stats.area.field} <small>га</small></div>
        </div>
        <div className="DashBlock">
          <h6 className="head">скорость</h6>
          <div className="text">{config.speed} <small>км/ч</small></div>
        </div>
      </header>

      <section className="DashBlocksComponentMainHeader">
        <div className="DashBlock DashBlockFlex">Бак</div>
        <div className="DashBlock DashBlockFlex DashBlockFlexData">
          <div>Расход, л/га</div>
          <div>Норма, л/га</div>
          <div>Давление, па</div>
          <div>Мощность, %</div>
        </div>
      </section>

      <section className="DashBlocksComponentMain">

        <div className={tankClass(0)} onClick={() => onTankClick(config.tanks[0])}>
          <div className="DashBlockInner DashBlockInnerStart">
            1 - {config.tanks[0].balance} л
          </div>
        </div>
        <div className="DashBlock DashBlockFirst DashBlockDataFlex">
          <div className={rateClass(0)}>
            {config.tanks[0].rate}
          </div>
          <div className="DashBlockInner DashBlockInnerData">
            {config.tanks[0].norm}
          </div>
          <div className="DashBlockInner DashBlockInnerData">
            {config.tanks[0].sensorPressure}
          </div>
          <div className="DashBlockInner DashBlockInnerData">
            {config.tanks[0].pumpPower}
          </div>
        </div>

        <div className={tankClass(1)} onClick={() => onTankClick(config.tanks[1])}>
          <div className="DashBlockInner DashBlockInnerStart" >
            2 - {config.tanks[1].balance} л
          </div>
        </div>
        <div className="DashBlock DashBlockFirst DashBlockDataFlex">
          <div className={rateClass(1)}>
            {config.tanks[1].rate}
          </div>
          <div className="DashBlockInner DashBlockInnerData">
            {config.tanks[1].norm}
          </div>
          <div className="DashBlockInner DashBlockInnerData">
            {config.tanks[1].sensorPressure}
          </div>
          <div className="DashBlockInner DashBlockInnerData">
            {config.tanks[1].pumpPower}
          </div>
        </div>

        <div className={tankClass(2)} onClick={() => onTankClick(config.tanks[2])}>
          <div className="DashBlockInner DashBlockInnerStart">
            3 - {config.tanks[2].balance} л
          </div>
        </div>
        <div className="DashBlock DashBlockFirst DashBlockDataFlex">
          <div className={rateClass(2)}>
            {config.tanks[2].rate}
          </div>
          <div className="DashBlockInner DashBlockInnerData">
            {config.tanks[2].norm}
          </div>
          <div className="DashBlockInner DashBlockInnerData">
            {config.tanks[2].sensorPressure}
          </div>
          <div className="DashBlockInner DashBlockInnerData">
            {config.tanks[2].pumpPower}
          </div>
        </div>
      </section>


    </section>
  )
}

export default DashBlocksComponent;
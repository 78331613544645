import React from 'react';
import { useObservable } from 'rxjs-hooks';

import SidebarComponent from '../../components/sidebar/sidebar.component';
import DashBlocksComponent from '../../components/dash-blocks/dash-blocks.component';

import { blocService } from '../../bloc/bloc.service';
import { Config } from '../../bloc/interfaces';

import ShapeIcon from '../../assets/img/shape.svg';
import './dashboard.component.scss';

const DashboardPage: React.FC = () => {
  const connectionStatus = useObservable(() => blocService.connectionStatus);
  const stats: Config | null = useObservable(() => blocService.getConfig());

  console.log('getConfig', stats);

  return (
    <section className="DashboardPage">
      <header className="DashboardPageHeader">
        <ul className="Nav">
          <li>
            {!!connectionStatus && <span className="active">Подключен</span>}
            {!connectionStatus && <span>Не Подключен</span>}
          </li>
          <li>
            GPS
          </li>
          <li>
            <img src={ShapeIcon} alt="shape" />
          </li>
        </ul>
        {!connectionStatus && (
        <div className="Notify">
          WiFi сеть A3Tech не подключена
        </div>
        )}
      </header>
      <section className="DashboardPageGrid">
        <DashBlocksComponent />
      </section>
      <aside className="DashboardPageSidebar">
        <SidebarComponent />
      </aside>
    </section>
  )
}

export default DashboardPage;
import React from 'react';
import { Switch, Route, Redirect } from 'react-router';

import ConnetionsComponent from '../components/connection.modal/connetion.component';
import TankComponent from '../components/tank.modal/tank.component';
import FieldComponent from '../components/field.modal/field.component';
import FieldCompleteComponent from '../components/field-complete.modal/field-complete.component';
import ConfigComponent from '../components/config.modal/config.component';
import SettingsComponent from '../components/settings.modal/setting.component';

import DashboardPage from '../pages/dashboard/dashboard.component';
import StatsPage from '../pages/stats/stats.component';


import './App.scss';

const App: React.FC = () => {
  return (
    <section className="App">
      <Switch>
        <Route path="/dashboard">
          <DashboardPage />
        </Route>
        <Route path="/statistics">
          <StatsPage />
        </Route>
        <Redirect to="/dashboard" />
      </Switch>
      <section className="Modals">
        <ConnetionsComponent />
        <TankComponent />
        <FieldComponent />
        <FieldCompleteComponent />
        <ConfigComponent />
        <SettingsComponent />
      </section>
    </section>
  );
}

export default App;

import React from 'react';
import { useObservable } from 'rxjs-hooks';

import { blocService, ModalTypes } from '../../bloc/bloc.service';

// import './field.component.scss';

const ConfigComponent: React.FC = () => {
  const activeModal = useObservable(() => blocService.activeModal);
  const activeClass = activeModal === ModalTypes.Configuration ? ' active' : '';

  const dissmissModals = () => blocService.dissmissModals();

  const onSubmit = () => {
    // TODO
    dissmissModals();
  }

  return (
    <section className={'Modal FieldComponent' + activeClass}>
      <div className="ModalBackdrop"></div>
      <div className="ModalBody">
        <h1>
          <span className="closeBtn" onClick={dissmissModals}>&times;</span>
          <span>Настройки</span>
        </h1>
        <hr />
        <section>
          <br /><br />
        </section>
        <section>
          <p>Виртуальная скорость, км/час</p>
          <br />
          <p>
            <input className="RangeInput" type="range" min="0" max="100" defaultValue="3" step="1" />
          </p>
          <br />
          <p>Мощность насоса, %</p>
          <br />
          <p>
            <input className="RangeInput" type="range" min="0" max="100" defaultValue="30" step="1" />
          </p>
          <br />
          <p>
            <input className="RangeInput" type="range" min="0" max="100" defaultValue="60" step="1" />
          </p>
          <br />
          <p>
            <input className="RangeInput" type="range" min="0" max="100" defaultValue="45" step="1" />
          </p>
        </section>
        <br /><br />
        <div className="Buttons textCenter">
          <button className="Btn BtnBlock" onClick={onSubmit}>Закрыть</button>
        </div>
      </div>
    </section>
  )
}

export default ConfigComponent;
import React from 'react';
import { useObservable } from 'rxjs-hooks';
import { useHistory } from "react-router-dom";

import { blocService, ModalTypes } from '../../bloc/bloc.service';
import { Field } from '../../bloc/interfaces';

import ConfigurationIcon from '../../assets/img/configuration.svg';
import SettingsIcon from '../../assets/img/settings.svg';
import StatsIcon from '../../assets/img/statistic.svg';

import './sidebar.component.scss';

const SidebarComponent: React.FC = () => {
  const history = useHistory();
  const field: Field | null = useObservable(() => blocService.field);

  const onStatisticTap = () => {
    history.push('/statistics');
  }

  const onConfigurationTap = () => {
    blocService.openModal(ModalTypes.Configuration);
  }

  const onSettingsTap = () => {
    blocService.openModal(ModalTypes.Setting);
  }
  
  return (
    <section className="SidebarComponent">
      <button className="SidebarComponentBtn" onClick={onConfigurationTap}>
        <img src={ConfigurationIcon} alt="configuration" />
      </button>
      {!!field && (
      <button className="SidebarComponentBtn" onClick={onStatisticTap}>
        <img src={StatsIcon} alt="statistic" />
      </button>
      )}
      <button className="SidebarComponentBtn" onClick={onSettingsTap}>
        <img src={SettingsIcon} alt="settings" />
      </button>
    </section>
  )
}

export default SidebarComponent;
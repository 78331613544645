import React from 'react';
import { useHistory } from "react-router-dom";
import { useObservable } from 'rxjs-hooks';

import { blocService } from '../../bloc/bloc.service';
import { Stats, Field, Config } from '../../bloc/interfaces';

import BackIcon from '../../assets/img/back-arrow.svg';
import './stats.component.scss';

const StatsPage: React.FC = () => {
  const history = useHistory();
  
  const stats: Stats | null = useObservable(() => blocService.getStat());
  const config: Config | null = useObservable(() => blocService.getConfig());
  const field: Field | null = useObservable(() => blocService.field);

  const onBackTap = () => {
    history.push('/dashboard');
  }

  console.log('getStats', stats, field, blocService);

  return (
    <section className="StatsPage">
      <h1>
        <img src={BackIcon} alt="back" onClick={onBackTap} />
        <span>Статистика поля {field && field.name}</span>
      </h1>
      <header className="StatsBlockWrapper">
        <div className="StatsBlock">
          <h6 className="head">Пройдено</h6>
          <div className="text">{!!stats && stats.milage.field} <small>км</small></div>
        </div>
        <div className="StatsBlock">
          <h6 className="head">Обработано</h6>
          <div className="text">{!!stats && stats.area.field} <small>га</small></div>
        </div>
        <div className="StatsBlock">
          <h6 className="head">Вид работ</h6>
          <div className="text light">{field && field.jobTitle}</div>
        </div>
      </header>

      <section className="StatsTableWrapper">
        <table>
          <thead>
            <tr>
              <th>Бак</th>
              <th>Удобрение</th>
              <th className="textRight">Норма впрыска</th>
              <th className="textRight">Израсходовано</th>
              <th className="textRight">Заправок</th>
            </tr>
          </thead>
          <tbody>
            {!!stats && stats.tanks.map((tank, key) => (
              <tr key={key}>
                <td>Бак {key + 1}</td>
                <td>{field && field.tanks[key].title}</td>
                <td className="textRight">{tank.field} л/га</td>
                <td className="textRight">{config && config.tanks[key].balance} л</td>
                <td className="textRight">{tank.refuel} шт</td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
    </section>
  )
}

export default StatsPage;
import Axios from 'axios-observable';

export interface MayHaveId {
  id?: number; // you can use any type, number is just an example
}

function mergeArrays<T1 extends MayHaveId, T2 extends MayHaveId>(arr1: Array<T1>, arr2: Array<T2>): Array<T2> {
  return arr2
    .map(el => {
      const el2: T1 | undefined = arr1.find(v => v.id === el.id);
      return el ? { ...el2, ...el } as T2 : el;
    });
}

const axiosDefaults = {
    baseURL: 'http://192.168.4.1/',
  timeout: 1000
};

const axiosInstance = Axios.create(axiosDefaults);

export { axiosInstance, mergeArrays }; 
import React, { useState } from 'react';
import { useObservable } from 'rxjs-hooks';

import { blocService, ModalTypes } from '../../bloc/bloc.service';

import './field.component.scss';
import { TankField } from '../../bloc/interfaces';

const defaultTank: TankField = {
  title: 'Селитра',
  norm: 10
}

const FieldComponent: React.FC = () => {
  const activeModal = useObservable(() => blocService.activeModal);
  const activeClass = activeModal === ModalTypes.Field ? ' active' : '';
  const [name, setName] = useState('поле 1');
  const [jobTitle, setJobTitle] = useState('Внесение микроелементов');
  const [tanks, setTanks] = useState([Object.assign({}, defaultTank), Object.assign({}, defaultTank), Object.assign({}, defaultTank)]);

  const dissmissModals = () => blocService.dissmissModals();

  const onTankNameChange = (value: string, key: number) => {
    tanks[key].title = value;
    setTanks([...tanks]);
  }

  const onTankNormChange = (value: any, key: number) => {
    tanks[key].norm = value;
    setTanks([...tanks]);
  }

  const onSubmit = () => {
    tanks.forEach(tank => {
      tank.norm = tank.norm || 0;
    });

    blocService.field.next({ name, jobTitle, tanks });
    dissmissModals();
  }

  return (
    <section className={'Modal FieldComponent' + activeClass}>
      <div className="ModalBackdrop"></div>
      <div className="ModalBody">
        <h1>
          <span className="closeBtn" onClick={dissmissModals}>&times;</span>
          <span>Поле</span>
          <span className="actionButton" onClick={onSubmit}>сохранить</span>
        </h1>
        <hr />
        <div>
          <form className="ScrollForm">
            <br /><br />
            <input type="text" className="TextInput" placeholder="Номер поля" value={name} onChange={e => setName(e.target.value)} />
            <input type="text" className="TextInput" placeholder="Вид работ" value={jobTitle} onChange={e => setJobTitle(e.target.value)} />
            {tanks.map((tank, key) => (
            <section key={key}>
              <h4>Бак {key + 1}</h4>
              <input type="text" className="TextInput" placeholder="Удобрение" value={tank.title} onChange={e => onTankNameChange(e.target.value, key)} />
              <input type="number" className="TextInput" placeholder="Норма впрыска, л / Га" value={tank.norm} onChange={e => onTankNormChange(e.target.value, key)} />
            </section>
            ))}
          </form>
        </div>
      </div>
    </section>
  )
}

export default FieldComponent;
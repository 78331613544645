import React from 'react';
import { useObservable } from 'rxjs-hooks';

import { blocService, ModalTypes } from '../../bloc/bloc.service';
import { Field } from '../../bloc/interfaces';

import './field-complete.component.scss';

const FieldCompleteComponent: React.FC = () => {
  const activeModal = useObservable(() => blocService.activeModal);
  const activeClass = activeModal === ModalTypes.FieldComplete ? ' active' : '';
  const field: Field | null = useObservable(() => blocService.field);

  const dissmissModals = () => blocService.dissmissModals();

  const onSubmit = () => {
    blocService.clearField();
    dissmissModals();
  }

  return (
    <section className={'Modal FieldCompleteComponent' + activeClass}>
      <div className="ModalBackdrop"></div>
      <div className="ModalBody">
        <h1>
          <span className="closeBtn" onClick={dissmissModals}>&times;</span>
          <span>Поле {field && field.name}</span>
        </h1>
        <hr />

        <p className="JobTitle">
          {field && field.jobTitle}
        </p>

        <div className="tableWrapper">
          <table className="table">
            <thead>
              <tr>
                <th>Бак</th>
                <th>Удобрение</th>
                <th>Норма впрыска</th>
              </tr>
            </thead>
            <tbody>
              {field && field.tanks.map((tank, key) => (
                <tr key={key}>
                  <td>{key + 1}</td>
                  <td>{tank.title}</td>
                  <td>{tank.norm}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <br /><br />
        <div className="Buttons textCenter">
          <button className="Btn BtnBlock" onClick={onSubmit}>Завершить работу</button>
        </div>
      </div>
    </section>
  )
}

export default FieldCompleteComponent;
import React from 'react';
import { useObservable } from 'rxjs-hooks';

import { blocService, ModalTypes } from '../../bloc/bloc.service';
import { Field } from '../../bloc/interfaces';

import ArrowIcon from '../../assets/img/arrow-right-grey.svg';
import SwitchIcon from '../../assets/img/switch.svg';

import './settings.component.scss';

const SettingsComponent: React.FC = () => {
  const activeModal = useObservable(() => blocService.activeModal);
  const activeClass = activeModal === ModalTypes.Setting ? ' active' : '';
  const field: Field | null = useObservable(() => blocService.field);

  const dissmissModals = () => blocService.dissmissModals();

  const onFieldClick = () => {
    const modal = field ? ModalTypes.FieldComplete : ModalTypes.Field;
    blocService.openModal(modal);
  }

  return (
    <section className={'Modal FieldComponent' + activeClass}>
      <div className="ModalBackdrop"></div>
      <div className="ModalBody">
        <h1>
          <span className="closeBtn" onClick={dissmissModals}>&times;</span>
          <span>Настройки</span>
        </h1>
        <hr />
        <div>
          <ul className="List">
            <li>
              <div className="ListButton">
                <span className="dimmed">Дифференцированный впрыск</span>
                <img src={SwitchIcon} alt="SwitchIcon" />
              </div>
            </li>
            <li>
              <button className="ListButton">
                <span>Настройки агрегата</span>
                <img src={ArrowIcon} alt="ArrowIcon" />
              </button>
            </li>
            <li>
              <button className="ListButton" onClick={onFieldClick}>
                <span>Поле</span>
                <span className="EndSlot">
                  {!field && <span>не создано</span>}
                  {!!field && <span>{field.name}</span>}
                  <img src={ArrowIcon} alt="ArrowIcon" />
                </span>
              </button>
            </li>
            <li>
              <button className="ListButton">
                <span>Язык</span>
                <span className="EndSlot">
                  <span>русский</span>
                  <img src={ArrowIcon} alt="ArrowIcon" />
                </span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </section>
  )
}

export default SettingsComponent;
import React from 'react';
import { useObservable } from 'rxjs-hooks';

import { blocService, ModalTypes } from '../../bloc/bloc.service';

import WiFiIcon from '../../assets/img/wifi.svg';

import './connetion.component.scss';

const ConnetionsComponent: React.FC = () => {
  const activeModal = useObservable(() => blocService.activeModal);
  const activeClass = activeModal === ModalTypes.Connetions ? ' active' : '';

  const dissmissModals = () => blocService.dissmissModals();

  return (
    <section className={'ConnetionsComponent' + activeClass}>
      <div className="">
        <img src={WiFiIcon} alt="wifi" />
      </div>
      <div>
        <h1>Подключите WiFi сеть A3Tech</h1> 
        <h3>Для подключения сети прочитайте рекомендации:</h3>
        <ol>
          <li>Зайдите в меню “Настройки” планшета и выберите пункт Wi-Fi</li>
          <li>В списке сетей найдите сеть - “A3Tech_0000”. Пароль для сети находится на обратной стороне планшета</li>
          <li>Запустите приложение снова</li>
        </ol>
        <div className="Buttons">
          <button className="Btn" onClick={dissmissModals}>Обновить</button>
          <button className="Btn BtnClear" onClick={dissmissModals}>Пропустить</button>
        </div>
      </div>
    </section>
  )
}

export default ConnetionsComponent;
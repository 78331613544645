// Stats

export interface TankStats {
  field: number;
  total: number;
  refuel: number;
}

export interface Milage {
  field: number;
  total: number;
}

export interface Stats {
  milage: Milage,
  area: Milage,
  tanks: TankStats[]
}

// Config

export enum GlobalMode { Auto, Manual, Test };

export interface TankConfig {
  size: number;
  norm: number;
  rate: number;
  pumpPower: number;
  manualPower: number;
  sensorPressure: number;
  balance: number;
  id: number;
}

export interface Config {
  globalStatus: number;
  globalMode: GlobalMode;
  gps: boolean;
  wifi: boolean;
  diffMode: boolean;
  deviceCount: number;
  deviceLength: number;

  tanks: TankConfig[];

  sensor: {
    min: number;
    max: number;
  };

  speed: number;

  minVelocity: number;
  pumpPowerMax: number;
  manualVirtualSpeed: number;
  alertTime: number;
  injectionWidth: number;
  maxPressure: number;
  timeStart: number;
  timeMetric: number;

  coordinates: {
    x: number;
    y: number;
  }
}

// App storage

export interface TankField {
  title: string;
  norm: number;
}

export interface Field {
  name: string;
  jobTitle: string;

  createdAt?: number;
  injection?: boolean;

  tanks: TankField[];
}

export interface AppConfig {
  lang: string;
  field: Field;
}